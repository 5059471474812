import Image from "next/image"
import Link from "next/link"

const Contact = () => {
  return (
    <div className="tw-mt-9 md:tw-mt-14">
      <h6 className="tw-text-[15px] tw-leading-6 md:tw-text-base md:tw-leading-5 tw-font-semibold tw-text-titleWhite">Contact Us</h6>
      <div className="tw-flex tw-flex-col tw-gap-5 tw-mt-3">
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className='after:tw-block after:tw-w-[24px] after:tw-h-[24px] after:tw-bg-[url(/assets/images/footer/mail_Icon.svg)] after:tw-bg-cover' />
          <Link href={"mailto:amc@motilaloswal.com"} title="amc@motilaloswal.com" className="tw-text-[14px] hover:tw-text-footerWhite hover:tw-underline tw-text-footerWhite">amc@motilaloswal.com</Link>
        </div>
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className='after:tw-block after:tw-w-[24px] after:tw-h-[24px] after:tw-bg-[url(/assets/images/footer/call_Icon.svg)] after:tw-bg-cover' />
          <div className="tw-flex tw-items-center">
            <Link href={"tel:+912240548002"} title="+912240548002" className="tw-text-sm hover:tw-text-white hover:tw-underline tw-text-footerWhite">+91-22-40548002</Link>
            /
            <Link href={"tel:+918108622222"} title="+918108622222" className="tw-text-sm hover:tw-text-white hover:tw-underline tw-text-footerWhite">8108622222</Link>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className='after:tw-block after:tw-w-[24px] after:tw-h-[24px] after:tw-bg-[url(/assets/images/footer/whatsapp_Icon.svg)] after:tw-bg-cover' />
          <Link href={"https://wa.me/7304921822"} title="+917304921822" className="tw-text-sm hover:tw-text-white hover:tw-underline tw-text-footerWhite">+91-7304921822</Link>
        </div>
      </div>
    </div>
  )
}

export default Contact