const socialsList = [
  {
    title: "Facebook",
    link: "https://www.facebook.com/motilaloswalamc",
    image: "/assets/images/footer/facebookIcon.svg"
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/motilaloswalamc",
    image: "/assets/images/footer/instagramIcon.svg"
  },
  {
    title: "X",
    link: "https://twitter.com/MotilalOswalAMC",
    image: "/assets/images/footer/excludeIcon.svg"
  },
  {
    title: "Youtube",
    link: "https://youtube.com/motilaloswalamc",
    image: "/assets/images/footer/youtubeIcon.svg"
  },
  {
    title: "Linkedin",
    link: "https://www.linkedin.com/company/motilal-oswal-amc",
    image: "/assets/images/footer/linkedinIcon.svg"
  },
]

const appLinksList = [
  {
    title: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.moamc.investorapp&hl=en_IN&gl=US",
    image: "/assets/images/footer/playstoreIcon.svg",
    width: 135
  },
  {
    title: "Apple Appstore",
    link: "https://apps.apple.com/in/app/motilal-oswal-mf-pms/id6463774883",
    image: "/assets/images/footer/appstoreIcon.svg",
    width: 120
  },
]

const appLinksListMobile = [
  {
    title: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.moamc.investorapp&hl=en_IN&gl=US",
    image: "/assets/images/footer/playstore_mobileIcon.svg",
    width: 135
  },
  {
    title: "Apple Appstore",
    link: "https://apps.apple.com/in/app/motilal-oswal-mf-pms/id6463774883",
    image: "/assets/images/footer/appstore_mobileIcon.svg",
    width: 120
  },
]

const copyrightLinks = [
  {
    title: "Disclaimer",
    link: "/disclaimer"
  },
  {
    title: "Voting Policies",
    link: "/assets/pdf/voting-policy.pdf"
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy"
  },
  {
    title: "Terms and Conditions",
    link: "/privacy-policy"
  },
  {
    title: "Sitemap",
    link: "/sitemap"
  },
]

const ourStrategies = [
  {
    title: "Motilal Oswal Value Migration Strategy",
    link: "/our-strategies/motilal-oswal-value-migration-strategy"
  },
  {
    title: "Motilal Oswal Next Trillion Dollar Opportunity",
    link: "/our-strategies/motilal-oswal-next-trillion-dollar-opportunity"
  },
  {
    title: "Motilal Oswal India Growth Fund",
    link: "/our-strategies/motilal-oswal-india-growth-fund"
  },
  {
    title: "Motilal Oswal Mid to Mega Strategy",
    link: "/our-strategies/motilal-oswal-mid-to-mega-strategy"
  },
  {
    title: "Motilal Oswal Founders Portfolio",
    link: "/our-strategies/motilal-oswal-founders-portfolio"
  }
]

const footerLinks = {
  "PMS": ourStrategies,
  "Tools & Services": [
    {
      title: "Compare Portfolio",
      link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}tools/compare-portfolio`
    },
    {
      title: "Update KYC",
      link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}modify-kyc`,
      external: true
    }
  ],

  "Quick Links": [
    {
      title: "About Us",
      link: "/about-us/overview"
    },
    {
      title: "Contact Us",
      link: "/contact-us"
    },
    {
      title: "Investor Charter",
      link: "/assets/pdf/Investor_Charter_MOAMC.pdf",
      external: true
    },
    {
      title: "Downloads",
      link: "/download/document-disclosure"
    },
    {
      title: "International Business",
      link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}InternationalBusiness`,
      external: true
    },
    {
      title: "FAQs",
      link: `${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}faq`,
      external: true
    },
  ],
  "Group Websites": [
    {
      title: "Financial Services",
      link: "http://www.motilaloswalgroup.com/",
      external: true,
    },
    {
      title: "Broking & Distribution",
      link: "https://www.motilaloswal.com/",
      external: true,
    },
    {
      title: "Private Wealth Management",
      link: "https://www.motilaloswalpwm.com/",
      external: true,
    },
    {
      title: "Home Finance",
      link: "https://motilaloswalhf.com/",
      external: true,
    },
    {
      title: "Institutional Equities",
      link: "http://www.motilaloswalgroup.com/Our-Businesses/Institutional-Equities",
      external: true,
    },
    {
      title: "Private Equity",
      link: "https://www.motilaloswalalt.com/",
      external: true,
    },
    {
      title: "Investment Banking",
      link: "http://www.motilaloswalgroup.com/Our-Businesses/Investment-Banking",
      external: true,
    },
  ],
}

export {
  appLinksList,
  appLinksListMobile,
  copyrightLinks, footerLinks,
  socialsList
}

