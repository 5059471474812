import Link from "next/link"
import { ourFundsList } from "./constants"
import Image from "next/image"
import { ourStrategiesList } from "./constants"


const OurStrategiesPopup
  = () => {
    return (
      <div class="menu-container tw-fixed  tw-top-[var(--top-for-menu-popup)] tw-left-1/2 -tw-translate-x-1/2 tw-w-[full] tw-z-[1000] translate-x-custom">
        <div className='menu-popup container tw-flex tw-justify-center tw-overflow-hidden tw-p-0'>
          <div className='tw-p-10 tw-bg-white tw-w-[852px] tw-max-w-full'>
            <h4 className="tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-pb-5 tw-border-b tw-border-greyBorder">Our PMS Strategies</h4>

            <div className="tw-mt-5 tw-grid tw-grid-rows-3 tw-grid-flow-col tw-gap-7">
              {ourStrategiesList.map((item, i) =>
                <a 
                  href={item.link} 
                  title={item.title} 
                  key={i} 
                  className={`tw-flex tw-items-center tw-gap-4 hover:tw-text-white hover:tw-decoration-white tw-bg-gradient-to-br ${item.style.gradientStart} ${item.style.gradientStop} tw-p-4 tw-rounded-2xl`}
                >
                  <div className={`tw-h-10 tw-w-10 tw-shrink-0 tw-grid tw-place-items-center tw-rounded-full ${item.style.iconBackground}`}>
                    <span className="tw-w-[22px] tw-h-[30px] tw-relative"><Image src={"/assets/images/header/lightning-icon.svg"} fill className="tw-object-contain" alt={item.title} /></span>
                  </div>
                  <p className="tw-text-white tw-text-[16px] tw-leading-4 tw-font-medium">{item.title}</p>
                  <div className="tw-relative tw-block tw-h-6 tw-w-6 tw-shrink-0 after:tw-absolute after:tw-inset-0 after:tw-bg-[url(/assets/images/header/arrow-right.svg)] after:tw-bg-contain"></div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

export default OurStrategiesPopup
