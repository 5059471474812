import { mobileHeaderLinks } from "./constants";
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import ProductList from "./ProductList";
import LoginButton from "./loginButton";

const MobileMenu = ({
  setIsMobileMenuOpen,
  selectedOption,
  options,
  customicon
}) => {
  const [openSection, setOpenSection] = useState("")
  const handleClick = (section) => setOpenSection(prev => (section === prev ? "" : section));

  return (
    <div className='tw-w-screen tw-h-full tw-flex tw-flex-col' id="mobile-header-menu">
      <div className="tw-flex tw-mx-4 tw-items-center tw-border-b tw-border-greyBorder tw-py-2">
        <div className="tw-w-fit fund-select tw-text-primaryBlue">
          <ProductList
            defaultValue={selectedOption}
            value={selectedOption}
            options={options}
            customicon={customicon}
          />
        </div>
      </div>
      <div className='tw-flex tw-justify-between tw-items-center tw-py-3 tw-px-4'>
        <div className="tw-flex tw-items-center tw-gap-4">
          <button onClick={() => setIsMobileMenuOpen(false)} className='after:tw-block after:tw-w-6 after:tw-h-6 after:tw-bg-[url(/assets/images/header/close.svg)] after:tw-bg-cover' />
          <Link href="/" title="Motilal Oswal Asset Management Company">
            <Image src="/assets/images/header/MO_Asset_Management_Logo_Mobile.svg" alt="Motilal Oswal Asset Management Company" height={40} width={103} />
          </Link>
        </div>

        <LoginButton variant="default" />
      </div>

      {Object.keys(mobileHeaderLinks).map((category, i) => {
        const id = category.toLowerCase().split(" ").join("-")
        return (
          <div key={i}>
            <div className='tw-flex tw-items-center tw-my-4 tw-mx-5' onClick={() => handleClick(id)}>
              <h5 className='tw-text-base tw-leading-5 tw-font-medium'>{category}</h5>
              <div className={`tw-ml-auto after:tw-block tw-transition-all ${openSection === id ? "-tw-rotate-180" : ""} after:tw-w-[15px] after:tw-h-[15px] after:tw-bg-[url(/assets/images/header/arrow_down_thin.svg)] after:tw-bg-cover`} />

            </div>
            <div className={`tw-flex tw-flex-col tw-transition-all tw-duration-300 tw-overflow-hidden ${openSection === id ? "tw-max-h-[2000px]" : "tw-max-h-0"}`}>
              {mobileHeaderLinks[category].map((item, j) => {
                return item.isHeading
                  ? <h6 key={j} className={`tw-text-sm tw-font-semibold tw-px-7 tw-py-3 tw-m-0 ${item.title === "Important Links" ? "tw-bg-[#EEF5FD]" : ""}`}>{item.title}</h6>
                  : item.image
                    ? <div className="tw-flex tw-items-center tw-gap-4 tw-mx-7 tw-my-3">
                      <Image src={item.image} alt={item.title} height={32} width={32} class="tw-h-8 tw-w-8" />
                      <a key={j} href={item.link} title={item.title} className='tw-text-sm tw-leading-5 tw-w-fit tw-text-grey' target={item.external ? "_blank" : "_self"}>{item.title}</a>
                    </div>
                    :
                    <div className={`tw-px-7 tw-py-3 tw-w-full ${item.isImportantLink ? "tw-bg-[#EEF5FD]" : ""}`}>
                      <Link key={j} href={item.link} title={item.title} className="tw-text-sm tw-leading-5 tw-w-fit tw-text-grey" target={item.external ? "_blank" : "_self"}>{item.title}</Link>
                    </div>
              }
              )}
            </div>
          </div>
        )
      }
      )}

      <div className='tw-mx-5 tw-py-5 tw-flex tw-flex-col tw-mt-auto'>
        <Link
          href={"tel:+91 81086 22222"}
          title='+91 81086 22222'
          className='tw-text-sm tw-no-underline tw-bg-white tw-border tw-border-borderColor tw-font-semibold tw-w-full tw-py-3 tw-flex tw-items-center tw-justify-center tw-gap-1 tw-rounded-[8px] tw-font-semibold tw-text-primaryBlue hover:tw-text-primaryBlue hover:tw-border-borderColor hover:tw-bg-flatBlue'
        >
          <Image src="/assets/images/header/ic_phone _blue.svg" height={20} width={20} alt="+91 81086 22222" />
          +91 81086 22222
        </Link>
        <Link
          href="/our-strategies"
          title='Quick Invest'
          className='tw-text-sm tw-bg-primaryBlue tw-text-white tw-font-semibold tw-w-full tw-py-3 tw-text-center tw-rounded-[8px] tw-mt-3 hover:tw-bg-hoverButton tw-underline-none hover:tw-underline-none hover:tw-text-white'
        >
          Quick Invest
        </Link>
      </div>
    </div>
  )
}

export default MobileMenu