import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';  // Import the up arrow icon
import Image from 'next/image';

const loginButtonStyle = {
  default: "tw-text-primaryBlue tw-font-medium tw-px-[8px] tw-border tw-py-[3px] tw-flex tw-items-center tw-gap-[6px] tw-rounded-[8px] tw-border-t-[1px] tw-border-l",
  outlined: "tw-text-primaryBlue tw-font-medium tw-px-[8px] tw-border tw-py-[3px] tw-flex tw-items-center tw-gap-[6px] tw-rounded-[8px] tw-border-t-[1px] tw-border-",
  outlinedDark: "tw-capitalize tw-font-medium tw-text-sm tw-px-4 tw-py-1 tw-text-black tw-border tw-border-solid tw-border-black tw-rounded-full"
}

// variant = "default" | "outlined"
const LoginDropdown = ({ variant = "default" }) => {
  const [clickedLogin, setclickedLogin] = useState(null);
  const router = useRouter();
  const open = Boolean(clickedLogin);

  const handleOpenMenu = (event) => {
    setclickedLogin(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setclickedLogin(null);
  };

  const handleLoginRedirect = (path) => {
    handleCloseMenu();
    router.push(path);
  };

  return (
    <>
      <button
        aria-controls={open ? 'login-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        className={loginButtonStyle[variant]}
      >
      <Image src="/assets/images/header/userCircle.svg" alt="User Icon" width={20} height={20} />

      <span className="tw-text-[14px] tw-font-medium tw-leading-[20px] tw-text-left tw-underline-none tw-underline-offset-[3px]">
         Login
        </span>

      {/* Conditional rendering of the arrow icon */}
      {open ? (
        <KeyboardArrowUpRoundedIcon fontSize="small" />
      ) : (
        <KeyboardArrowDownRoundedIcon fontSize="small" />
      )}
      </button>

      {/* Dropdown menu */}
      <Menu
        id="login-menu"
        anchorEl={clickedLogin}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'login-button',
        }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiButtonBase-root.MuiMenuItem-root': {
            padding: '16px 64px 16px 0',
            margin: '0 24px'
          }
        }}
      >
        {/* Investor Login option */}
        <MenuItem onClick={() => handleLoginRedirect('/pms/login')} className='custom-border'>
          <ListItemIcon className='tw-text-black'>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography className='tw-text-sm tw-font-medium'>Investor Login</Typography>
        </MenuItem>

        {/* Partner Login option */}
        <MenuItem onClick={() => handleLoginRedirect(`${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}investonline/dist`)}>
          <ListItemIcon className='tw-text-black'>
            <AssignmentIndIcon fontSize="small" />
          </ListItemIcon>
          <Typography className='tw-text-sm tw-font-medium'>Partner Login</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LoginDropdown;
