import Link from "next/link"
import { investorEducationList, toolsAndCalculatorsList, importantLinks } from "./constants"

const InvestorServicesPopup = () => {
  //tw-top-[100px] remove it to show whatsapp banner 
  return (
    <div className="menu-container tw-fixed tw-left-0  tw-top-[var(--top-for-menu-popup)] tw-w-full tw-z-[1000]">
      <div className='menu-popup container tw-flex tw-overflow-hidden tw-p-0'>
        <div className='tw-p-8 tw-bg-white tw-w-2/3'>
          <h4 className="tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-pb-5 tw-border-b tw-border-greyBorder">Investor Education</h4>

          <div className="tw-mt-5 tw-grid tw-grid-rows-3 tw-grid-flow-col tw-gap-y-6">
            {investorEducationList.map((item, i) =>
              <Link key={i} href={item.link} title={item.title} className="hover:tw-text-black tw-text-base tw-w-fit tw-font-medium" target={item.external ? "_blank" : "_self"}>{item.title}</Link>
            )}
          </div>
        </div>
        <div className="tw-w-1/3 tw-py-8 tw-pr-8 tw-pl-12 tw-bg-[#EEF5FD]">
          <h4 className="tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-pb-5 tw-border-b tw-border-greyBorder">Important Links</h4>

          <div className="tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-y-6">
            {importantLinks.map((item, i) =>
              <div key={i} className="tw-flex tw-items-start tw-gap-4">
                <Link href={item.link} title={item.title} className="hover:tw-text-black tw-text-base tw-mt-1" target={item.external ? "_blank" : "_self"}>{item.title}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestorServicesPopup