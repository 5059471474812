import Link from "next/link"
import { useState } from 'react'
import { footerLinks } from "./constants"

const linkTitleStyle = "tw-text-[15px] tw-leading-6 md:tw-text-base md:tw-leading-5 tw-font-semibold md:tw-mb-4 tw-text-titleWhite"
const linkStyle = "tw-w-fit hover:tw-text-greyBorder hover:md:tw-underline tw-text-[13px] tw-leading-6 max-md:tw-mt-2  md:tw-text-sm md:tw-leading-6 md:tw-mb-1 tw-text-borderColor"

const Links = () => {
  const [openSection, setOpenSection] = useState("")
  return (
    <>
      <div className="tw-hidden md:tw-block tw-z-[2]"><Desktop /></div>
      <div className="tw-block md:tw-hidden"><Mobile openSection={openSection} setOpenSection={setOpenSection} /></div>
    </>
  )
}

const Desktop = () => {
  return (
    <div className="tw-grid links-column-template tw-gap-x-7 tw-gap-y-10">
      {Object.keys(footerLinks).map(item =>
        <div key={item} className="tw-flex tw-flex-col">
          <h5 className={linkTitleStyle}>{item}</h5>
          {footerLinks[item].map((link, i) =>
            <Link key={i} href={link.link} title={link.title} className={linkStyle} target={link.external ? "_blank" : "_self"}>{link.title}</Link>)}
        </div>
      )}
    </div>
  )
}

const Mobile = ({ openSection, setOpenSection }) => {
  const handleClick = (section) => {
    if (section === openSection) setOpenSection("")
    else setOpenSection(section)
  }

  return (
    <div className="tw-flex tw-flex-col">
      {Object.keys(footerLinks).map(item => {
        const id = item.toLowerCase().split(" ").join("-")
        return (
          <div key={item} className="tw-flex tw-flex-col tw-mb-5 tw-pb-5 tw-border-b tw-border-grey">
            <div className="tw-flex tw-items-center tw-justify-between" onClick={() => handleClick(id)}>
              <h5 className={linkTitleStyle}>{item}</h5>
              <div className={`tw-transition-all tw-duration-300 ${openSection === id ? "-tw-rotate-180" : ""} after:tw-block after:tw-w-[16px] after:tw-h-[16px] after:tw-bg-[url(/assets/images/footer/arrow_down_thin.svg)] after:tw-bg-cover`} />
            </div>
            <div className={`tw-flex tw-flex-col tw-mt-1 tw-transition-all tw-duration-300 tw-overflow-hidden ${openSection === id ? "tw-max-h-[1000px]" : "tw-max-h-0"}`}>
              {footerLinks[item].map((link, i) =>
                <Link key={i} href={link.link} title={link.title} className={linkStyle} target={link.external ? "_blank" : "_self"}>{link.title}</Link>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Links